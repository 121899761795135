
import KTextField from "@/components/crud/fields/KTextField.vue";
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import { getThrottleErrorMinutes } from "@/application/util/ThrottleErrorResponse";
import { reset } from "@/modules/authorisation/api/reset";
import Vue, { VueConstructor } from "vue";
import { AxiosError, AxiosResponse } from "axios";
import KBtn from "@/components/KBtn.vue";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        form: {
          validate: () => void;
        };
      };
    }
  >
).extend({
  name: "PasswordReset",
  components: {
    KBtn,
    KTextField,
    KFieldGroup,
  },
  data() {
    return {
      alertType: "success",
      alertMessage: "",
      isLoading: false,
      isValid: false,
      form: {
        email: "",
        password: "",
        passwordConfirmation: "",
      },
    };
  },
  created() {
    this.form.email = (this.$route.query.email as string) || "";
  },
  methods: {
    handleRegister(): void {
      this.$refs.form.validate();

      if (!this.isValid) return;

      this.isLoading = true;
      this.alertType = "error";
      this.alertMessage = "";

      reset(
        this.form.email,
        this.$route.params.token,
        this.form.password,
        this.form.passwordConfirmation
      )
        .then(() => {
          this.$router.push({
            name: "login",
            query: {
              message: "authorisation.passwordReset.successMessage",
            },
          });
        })
        .catch((error) => {
          this.alertType = "error";
          const { response } = error as AxiosError;
          const { status } = response as AxiosResponse;

          if (status === 429) {
            this.alertMessage = this.$t("errors.429", {
              minutes: getThrottleErrorMinutes(response as AxiosResponse),
            }) as string;
          } else if (status === 400) {
            this.alertMessage = this.$t(
              "authorisation.passwordReset.errorMessage"
            ) as string;
          }

          this.$refs.form.validate();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
