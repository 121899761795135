import { post } from "@/application/api";
import { AxiosResponse } from "axios";

export interface ResetResponse {
  data: {
    message: string;
  };
}

export const reset = (
  email: string,
  token: string,
  password: string,
  passwordConfirmation: string
): Promise<AxiosResponse<ResetResponse>> => {
  return post("password/reset", {
    email,
    token,
    password,
    passwordConfirmation,
  });
};
